
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { MenuOutlined, PlusOutlined, UserOutlined, EllipsisOutlined, DownOutlined } from '@ant-design/icons-vue'
import { green, red } from '@ant-design/colors'
import { createDeviceDetector } from 'next-vue-device-detector'
import { useAppStore } from '@/store/app'
import { Member, MemberUpdate } from '@/types/Member'
import dayjs from 'dayjs'
import api from '@/apis'
import CreateMemberModal from '@/components/ui/CreateMemberModal.vue'
import EditMemberModal from '@/components/ui/EditMemberModal.vue'
import MemberInfoDrawer from './components/MemberInfoDrawer.vue'
import MemberFormModal from '@/components/ui/MemberFormModal.vue'

const columns = [{
  title: '會員編號',
  dataIndex: 'member_id',
  key: 'member_id'
}, {
  title: '姓名',
  dataIndex: 'name',
  key: 'name'
}, {
  title: '性別',
  dataIndex: 'gender',
  key: 'gender'
}, {
  title: '生日',
  dataIndex: 'birthdate',
  key: 'birthdate'
}, {
  title: '聯絡電話',
  dataIndex: 'phone',
  key: 'phone'
}, {
  title: 'LINE ID',
  dataIndex: 'line_friend_id',
  key: 'line_friend_id'
}, {
  title: '綁定狀態',
  dataIndex: 'line_id',
  key: 'line_id'
}, {
  title: '操作',
  key: 'action'
}]


export default defineComponent({
  components: {
    CreateMemberModal,
    EditMemberModal,
    MemberInfoDrawer,
    MemberFormModal,
    MenuOutlined,
    PlusOutlined,
    UserOutlined,
    EllipsisOutlined,
    DownOutlined
  },
  setup() {
    const router = useRouter();
    const appStore = useAppStore();
    const device = createDeviceDetector();

    /* Status */
    const loading = ref<boolean>(false);

    /* Data */
    const usersData = ref<Array<Member>>([]);

    /* Api */
    const getUsersData = (async () => {
      usersData.value = [];
      loading.value = true;

      const response = await api.v1.user.list();
      const result: Array<Member> = response.data.result;
      result.forEach(item => {
        item.key = item.uid;
        usersData.value.push(item)
      });

      console.log(usersData.value)

      loading.value = false;
    });

    const searchUsersData = (async () => {
      usersData.value = [];
      loading.value = true;

      const response = await api.v1.user.search({
        q: searchValue.value
      });
      const result: Array<Member> = response.data.result;
      result.forEach(item => {
        item.key = item.uid;
        usersData.value.push(item)
      });

      loading.value = false;
    });

    const updateOne = (async (uid: string, data: MemberUpdate) => {
      try {
        await api.v1.user.updateOne(uid, data);
        message.success('更新會員資料成功');
      } catch (error) {
        message.error('更新會員資料失敗，請稍後再試');
      }
    });

    const deleteOne = (async (uid: string) => {
      try {
        await api.v1.auth.deleteOne({ uid: uid });
        message.success('刪除會員成功');
      } catch (error) {
        message.error('刪除會員失敗，請稍後再試');
      } finally {
        await getUsersData();
      }
    });

    const unbind = (async (uid: string, line_id: string) => {
      try {
        await api.v1.line.userUnbind({
          uid: uid,
          line_id: line_id
        });

        for (let i=0; i<usersData.value.length; i++) {
          if (usersData.value[i].uid == uid) {
            usersData.value[i].line_id = '';
            break
          }
        }

        message.success('LINE 解除綁定成功');
      } catch (error) {
        message.error('LINE 解除綁定失敗，請稍後再試');
      }
    });

    /* Mounted */
    onMounted(async () => {
      await getUsersData();
    });

    /* Table */
    const searchValue = ref<string>('');
    const genderOptions = reactive([{
      value: 'male',
      label: '先生'
    }, {
      value: 'female',
      label: '女士'
    }]);

    return {
      router,
      device,

      /* Store */
      appStore,
      
      /* Status */
      loading,

      /* Api */
      getUsersData,
      searchUsersData,
      updateOne,
      deleteOne,
      unbind,

      /* Data */
      columns,
      usersData,

      /* Table */
      searchValue,
      genderOptions,

      /* Antd */
      message,
      green,
      red
    }
  },
  data() {
    return {
      /* Create member modal */
      createMemberModal: {
        visible: false,
        role: 1
      },

      /* Edit member modal */
      editMemberModal: {
        visible: false,
        role: 1,
        data: {} as Member
      },

      /* Member info drawer */
      memberInfoDrawer: {
        visible: false,
        uid: ''
      },

      /* Member Form Modal */
      memberFormModal: {
        visible: false,
        memberData: {} as Member
      }
    }
  },
  methods: {
    /* Router */
    toMemberPage(member_id: string) {
      this.router.push({
        name: 'AppMemberPage',
        params: {
          member_id: member_id
        }
      });
    },

    /* Utils */
    calculateAge(birthdate: string) {
      const ageDifMs = Date.now() - new Date(birthdate).getTime();
      const ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },

    /* Create member modal */
    openCreateMemberModal() {
      this.createMemberModal.visible = true;
    },
    async handleCreateMemberModalConfirm() {
      await this.getUsersData();
      this.createMemberModal.visible = false;
    },
    handleCreateMemberModalCancel() {
      this.createMemberModal.visible = false;
    },

    /* Edit member modal */
    openEditMemberModal(data: Member) {
      console.log(data);
      this.editMemberModal.data = data;
      this.editMemberModal.visible = true;
    },
    async handleEditMemberModalConfirm() {
      const uid = this.editMemberModal.data['uid'];

      let updatedData = {} as MemberUpdate;
      updatedData.name = this.editMemberModal.data.name;
      updatedData.avatar = this.editMemberModal.data.avatar;
      updatedData.gender = this.editMemberModal.data.gender;
      updatedData.birthdate = dayjs(this.editMemberModal.data.birthdate).format('YYYY-MM-DD');
      updatedData.phone = this.editMemberModal.data.phone;
      updatedData.line_friend_id = this.editMemberModal.data.line_friend_id;
      updatedData.address = this.editMemberModal.data.address;

      await this.updateOne(uid, updatedData);
      await this.getUsersData();

      this.editMemberModal.visible = false;
    },
    handleEditMemberModalCancel() {
      this.editMemberModal.visible = false;
    },

    /* Member info drawer */
    openMemberInfoDrawer(uid: string) {
      this.memberInfoDrawer.uid = uid;
      this.memberInfoDrawer.visible = true;
    },
    handleMemberInfoDrawerClose() {
      this.memberInfoDrawer.visible = false;
    },

    /* Member Form Modal */
    openMemberFormModal(memberData: Member) {
      this.memberFormModal.memberData = memberData;
      this.memberFormModal.visible = true;
    },
    handleMemberFormModalClose() {
      this.memberFormModal.visible = false;
    }
  }
})
