<template>
  <a-modal
    class="member-form-modal"
    :visible="visible"
    :title="`${name}的表單管理`"
    :maskClosable="false"
    :footer="false"
    @cancel="handleCancel"
  >
    <a-tabs v-model:activeKey="selectedKey">
      <a-tab-pane key="1" tab="已啟用" />
      <a-tab-pane key="0" tab="未啟用" />
    </a-tabs>

    <div class="modal__body">
      <template v-if="selectedKey == '1'">
        <a-list
          item-layout="horizontal"
          :data-source="activedFormsData"
        >
          <template #renderItem="{ item }">
            <a-list-item>
              <template #actions>
                <a-popconfirm
                  title="確認刪除表單"
                  ok-text="確定"
                  cancel-text="取消"
                  danger
                  @confirm="inactiveForm(item.form_id, item.user_id)"
                  @cancel="message.info('取消刪除表單動作')"
                >
                  <a :style="{ color: '#ff4d4f' }">刪除表單</a>
                </a-popconfirm>

                <a @click="toFillFormPage(item.code)">
                  <span :style="{ display: 'inline-block', marginRight: '4px' }">填寫表單</span>
                  <a-tooltip>
                    <template #title>
                      <span>複製表單連結</span>
                    </template>
                    <a @click.stop="copyLink(item.code)" :style="{ fontSize: '1.1rem' }">
                      <span class="mdi mdi-link"></span>
                    </a>
                  </a-tooltip>
                </a>
              </template>
              <a-skeleton avatar :title="false" :loading="!!item.loading" active>
                <a-list-item-meta :description="item.form.description">
                  <template #title>
                    <span :style="{ fontSize: '1rem' }">{{ item.form.name }}</span>
                  </template>
                </a-list-item-meta>
              </a-skeleton>
            </a-list-item>
          </template>
        </a-list>
      </template>

      <template v-if="selectedKey == '0'">
        <a-list
          item-layout="horizontal"
          :data-source="unactivedFormsData"
        >
          <template #renderItem="{ item }">
            <a-list-item>
              <template #actions>
                <a @click="activeForm(item.id)">啟用表單</a>
              </template>
              <a-skeleton avatar :title="false" :loading="!!item.loading" active>
                <a-list-item-meta :description="item.description">
                  <template #title>
                    <span :style="{ fontSize: '1rem' }">{{ item.name }}</span>
                  </template>
                </a-list-item-meta>
              </a-skeleton>
            </a-list-item>
          </template>
        </a-list>
      </template>
    </div>    
  </a-modal>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { message } from 'ant-design-vue'
import { copyText } from 'vue3-clipboard'
import api from '@/apis'

export default defineComponent({
  props: ['visible', 'memberData'],
  components: {},
  setup(props) {
    /* Tab */
    const selectedKey = ref('1');

    /* Data */
    const formsData = ref([]);
    const unactivedFormsData = ref([]);
    const activedFormsData = ref([]);

    /* Loading */
    const loading = ref(false);

    /* Watch */
    watch(() => props.visible, async function() {
      loading.value = true;

      selectedKey.value = '1';
      await initFormsData();

      if (activedFormsData.value.length == 0) selectedKey.value = '0';

      loading.value = false;
    });

    /* Get Forms Func */
    const initFormsData = (async () => {
      await getFormsData();
      await getMemberActivedFormsData();

      // 取出沒有被啟用的表單
      unactivedFormsData.value = [];
      const activedFormIds = activedFormsData.value.map(item => item.form_id);
      formsData.value.forEach(item => {
        if (!activedFormIds.includes(item.id)) unactivedFormsData.value.push(item);
      });
    });

    /* Api */
    const getFormsData = (async () => {
      const response = await api.v1.form.list({
        roles: '1'
      });
      formsData.value = [ ...response.data.result ];
      console.log(formsData.value);
    });

    const getMemberActivedFormsData = (async () => {
      const response = await api.v1.form.activedList({
        user_id: props.memberData.uid
      });
      activedFormsData.value = [ ...response.data.result ];
      console.log(activedFormsData.value);
    });

    const activeForm = (async (form_id) => {
      try {
        await api.v1.form.activeForm(form_id, {
          user_id: props.memberData.uid
        });
        message.success('啟用表單成功');
      } catch (error) {
        console.log(error);
        message.error('啟用表單發生錯誤，請稍後再試');
      } finally {
        selectedKey.value = '1';
        initFormsData();
      }
    });

    const inactiveForm = (async (form_id, user_id) => {
      try {
        await api.v1.form.inactiveForm(form_id, {
          user_id: user_id
        });
        message.success('刪除表單成功');
      } catch (error) {
        console.log(error);
        message.error('刪除表單發生錯誤，請稍後再試');
      } finally {
        initFormsData();
      }
    });

    return {
      message,

      /* Tab */
      selectedKey,

      /* Data */
      unactivedFormsData,
      activedFormsData,

      /* Api */
      activeForm,
      inactiveForm
    }
  },
  computed: {
    name() {
      if (Object.keys(this.memberData).length) return this.memberData.name
      else return ''
    }
  },
  methods: {
    handleCancel() {
      this.$emit('close');
    },

    /* Router */
    toFillFormPage(code) {
      const { href } = this.$router.resolve({
        name: 'FormFillPage',
        params: {
          code: code
        }
      });
      window.open(href, '_blank');
    },

    /* Copy */
    copyLink(code) {
      const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/form/fill/${code}`;
      copyText(url, undefined, (error, event) => {
        if (error) {
          message.error('複製表單連結發生錯誤，請稍後再試');
          console.log(error)
        } else {
          message.success('複製表單連結成功');
          console.log(event)
        }
      });
    }
  }
})
</script>

<style lang="scss">
.member-form-modal {
  width: 100% !important;
  max-width: 650px !important;

  .ant-modal-content > .ant-modal-body {
    padding: 12px 24px !important;
  }

  .modal__body {
    padding-bottom: 20px;
  }
}
</style>